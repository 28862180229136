/* eslint-disable no-process-env */
import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  shared: {
    VERCEL_URL: z
      .string()
      .optional()
      .transform((v) => (v ? `https://${v}` : undefined)),
    PORT: z.coerce.number().default(3000),
    NEXT_PUBLIC_SUPABASE_URL: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_ID: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
    NEXT_PUBLIC_GTM_CONTAINER_ID: z.string().min(5),
    NEXT_PUBLIC_ABSOLUTE_URL: z.string().min(5),
    NEXT_PUBLIC_APP_URL: z.string().min(5),
    NEXT_PUBLIC_LOGSNAG_PROJECT: z.string().min(3),
    NEXT_PUBLIC_LOGSNAG_DISABLED: z.boolean().default(false),
    NEXT_PUBLIC_GETSTREAM_API_KEY: z.string().min(10),
    NEXT_PUBLIC_GETSTREAM_APP_ID: z.string().min(5),
    NEXT_PUBLIC_BASELIME_API_KEY: z.string().min(10),
    NEXT_PUBLIC_OPS_ABSOLUTE_URL: z.string().min(5),
    NEXT_PUBLIC_APP_ABSOLUTE_URL: z.string().min(5),
    NEXT_PUBLIC_OPEN_PANEL_APP_CLIENT_ID: z.string().min(5),
    NEXT_PUBLIC_OPEN_PANEL_OPS_CLIENT_ID: z.string().min(5),
    NEXT_PUBLIC_APP: z.enum(['ops', 'app', 'sales-flow']),
  },
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app isn't
   * built with invalid env vars.
   */
  server: {
    DATABASE_URL: z.string().url(),
    DIRECT_URL: z.string().url().optional(),
    SUPABASE_SERVICE_ROLE_KEY: z.string().min(1),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    INTERCOM_TOKEN: z.string().min(10),
    INTERCOM_SECRET_KEY: z.string().min(10),
    INTERCOM_ADMIN_ID: z.string().min(7),
    INTERCOM_FRONTDESK_TEAM_ID: z.string().min(7),
    TRELLO_TOKEN: z.string().min(10),
    TRELLO_API_KEY: z.string().min(10),
    OPENAI_API_KEY: z.string().min(10),
    GOOGLE_SHEETS_API_KEY: z.string().min(10),
    RESEND_API_KEY: z.string().min(10),
    SUPABASE_WEBHOOK_TOKEN: z.string().min(10),
    IMPOWER_API_KEY: z.string().min(10),
    SLACK_USER_OAUTH_TOKEN: z.string().min(10),
    SLACK_BOT_OAUTH_TOKEN: z.string().min(10),
    LOGSNAG_PRIVATE_TOKEN: z.string().min(10),
    SUPABASE_AWS_ACCESS_KEY_ID: z.string().min(10),
    SUPABASE_AWS_SECRET_ACCESS_KEY: z.string().min(10),
    SUPABASE_STORAGE_BUCKET_NAME: z.string().min(3),
    GOOGLE_SHARED_DRIVE_ID: z.string().min(10),
    GETSTREAM_API_SECRET: z.string().min(20),
    BASELIME_API_KEY: z.string().min(10),
    APP_REVALIDATE_PATH: z.string().min(10),
    OPS_REVALIDATE_PATH: z.string().min(10),
    NEXT_RUNTIME: z.enum(['nodejs', 'edge']),
    OPEN_PANEL_APP_CLIENT_SECRET: z.string().min(10),
    OPEN_PANEL_OPS_CLIENT_SECRET: z.string().min(10),
  },
  /**
   * Specify your client-side environment variables schema here.
   * For them to be exposed to the client, prefix them with `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_SUPABASE_URL: z.string().min(1),
    // NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
  },
  /**
   * Destructure all variables from `process.env` to make sure they aren't tree-shaken away.
   */
  runtimeEnv: {
    VERCEL_URL: process.env.VERCEL_URL,
    PORT: process.env.PORT,
    DATABASE_URL: process.env.DATABASE_URL,
    DIRECT_URL: process.env.DIRECT_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    SUPABASE_SERVICE_ROLE_KEY: process.env.SUPABASE_SERVICE_ROLE_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ID: process.env.NEXT_PUBLIC_SUPABASE_ID,
    NODE_ENV: process.env.NODE_ENV,
    INTERCOM_TOKEN: process.env.INTERCOM_TOKEN,
    INTERCOM_ADMIN_ID: process.env.INTERCOM_ADMIN_ID,
    INTERCOM_FRONTDESK_TEAM_ID: process.env.INTERCOM_FRONTDESK_TEAM_ID,
    INTERCOM_SECRET_KEY: process.env.INTERCOM_SECRET_KEY,
    TRELLO_TOKEN: process.env.TRELLO_TOKEN,
    TRELLO_API_KEY: process.env.TRELLO_API_KEY,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    GOOGLE_SHEETS_API_KEY: process.env.GOOGLE_SHEETS_API_KEY,
    NEXT_PUBLIC_GTM_CONTAINER_ID: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
    RESEND_API_KEY: process.env.RESEND_API_KEY,
    SUPABASE_WEBHOOK_TOKEN: process.env.SUPABASE_WEBHOOK_TOKEN,
    IMPOWER_API_KEY: process.env.IMPOWER_API_KEY,
    NEXT_PUBLIC_ABSOLUTE_URL: process.env.NEXT_PUBLIC_ABSOLUTE_URL,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    SLACK_USER_OAUTH_TOKEN: process.env.SLACK_USER_OAUTH_TOKEN,
    SLACK_BOT_OAUTH_TOKEN: process.env.SLACK_BOT_OAUTH_TOKEN,
    LOGSNAG_PRIVATE_TOKEN: process.env.LOGSNAG_PRIVATE_TOKEN,
    NEXT_PUBLIC_LOGSNAG_PROJECT: process.env.NEXT_PUBLIC_LOGSNAG_PROJECT,
    NEXT_PUBLIC_LOGSNAG_DISABLED:
      process.env.NEXT_PUBLIC_LOGSNAG_DISABLED === 'true',
    SUPABASE_AWS_ACCESS_KEY_ID: process.env.SUPABASE_AWS_ACCESS_KEY_ID,
    SUPABASE_AWS_SECRET_ACCESS_KEY: process.env.SUPABASE_AWS_SECRET_ACCESS_KEY,
    SUPABASE_STORAGE_BUCKET_NAME: process.env.SUPABASE_STORAGE_BUCKET_NAME,
    GOOGLE_SHARED_DRIVE_ID: process.env.GOOGLE_SHARED_DRIVE_ID,
    NEXT_PUBLIC_GETSTREAM_API_KEY: process.env.NEXT_PUBLIC_GETSTREAM_API_KEY,
    NEXT_PUBLIC_GETSTREAM_APP_ID: process.env.NEXT_PUBLIC_GETSTREAM_APP_ID,
    GETSTREAM_API_SECRET: process.env.GETSTREAM_API_SECRET,
    BASELIME_API_KEY: process.env.BASELIME_API_KEY,
    NEXT_PUBLIC_BASELIME_API_KEY: process.env.NEXT_PUBLIC_BASELIME_API_KEY,
    APP_REVALIDATE_PATH: process.env.APP_REVALIDATE_PATH,
    OPS_REVALIDATE_PATH: process.env.OPS_REVALIDATE_PATH,
    NEXT_RUNTIME: process.env.NEXT_RUNTIME,
    NEXT_PUBLIC_OPS_ABSOLUTE_URL: process.env.NEXT_PUBLIC_OPS_ABSOLUTE_URL,
    NEXT_PUBLIC_APP_ABSOLUTE_URL: process.env.NEXT_PUBLIC_APP_ABSOLUTE_URL,
    NEXT_PUBLIC_OPEN_PANEL_APP_CLIENT_ID:
      process.env.NEXT_PUBLIC_OPEN_PANEL_APP_CLIENT_ID,
    NEXT_PUBLIC_OPEN_PANEL_OPS_CLIENT_ID:
      process.env.NEXT_PUBLIC_OPEN_PANEL_OPS_CLIENT_ID,
    NEXT_PUBLIC_APP: process.env.NEXT_PUBLIC_APP,
    OPEN_PANEL_APP_CLIENT_SECRET: process.env.OPEN_PANEL_APP_CLIENT_SECRET,
    OPEN_PANEL_OPS_CLIENT_SECRET: process.env.OPEN_PANEL_OPS_CLIENT_SECRET,
  },
  skipValidation:
    !!process.env.CI ||
    !!process.env.SKIP_ENV_VALIDATION ||
    process.env.npm_lifecycle_event === 'lint',
})
